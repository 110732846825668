import React from "react";

const Rss = () =>
    <svg
        className="icons-small"
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 24.332 24.332">
        <g>
            <g id="c40_rss">
                <path fill="#4f5969" d="M3.607,17.11C1.618,17.11,0,18.726,0,20.717c0,1.984,1.618,3.604,3.607,3.604s3.607-1.619,3.607-3.604
			C7.214,18.726,5.596,17.11,3.607,17.11z"/>
                <path fill="#4f5969" d="M0.375,7.951C0.169,7.951,0,8.12,0,8.328v4.578c0,0.206,0.169,0.374,0.375,0.374c5.879,0,10.665,4.784,10.665,10.665
			c0,0.205,0.166,0.375,0.375,0.375h4.581h0.016c0.209,0,0.377-0.17,0.377-0.375l-0.018-0.117
			C16.305,15.064,9.152,7.951,0.375,7.951z"/>
                <path fill="#4f5969" d="M24.311,23.828C24.246,10.681,13.531,0.01,0.375,0.01C0.169,0.01,0,0.179,0,0.387v4.711c0,0.207,0.169,0.375,0.375,0.375
			c10.186,0,18.472,8.287,18.472,18.473c0,0.205,0.168,0.375,0.373,0.375h4.713h0.02c0.205,0,0.379-0.17,0.379-0.375L24.311,23.828z
			"/>
            </g>
            <g id="Capa_1_3_">
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>

export default Rss;